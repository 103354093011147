import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2022_06_29_STI-revolutionizing-STI-testing.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
	<StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">Sexually transmitted infection (STI) rates are at all time highs, and current STI testing takes too 
					long and is not patient centric. Practices often must treat STIs presumptively while waiting for results, causing overuse of antibiotics 
					resulting in patient morbidity and an increase in antibiotic resistance. Once testing results are available 24-72 hours later, staff must 
					try to contact the patient, prescribe or change treatment, and spend additional time documenting.
					</p>
					<p data-aos="fade-up">
					Adoption of innovative POC STI testing allows for superior patient care, precise use of antibiotics for treatment, and adds an 
					opportunity to provide counseling and preventive measures. Patients benefit from the right treatment for the right problem, and 
					experience less anxiety and distress. Practices benefit from the speed and accuracy of testing, which results in staff efficiency of 
					care and less time charting, allowing for more patient encounters.
					</p>

					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Review of the current STI epidemic and impact on patient health</li>
						<li>Examine how innovative POC testing can increase efficiency and improved patient care</li>
						<li>Assess how testing accuracy, deployability, and speed can improve your workflow</li>
						<li>Evaluate how POC STI testing can provide clinical, operational, and financial benefits to your practice while increasing 
						both the quality of care and satisfaction of your patients</li> 
					</ul>
				  </div>
				</div>
			
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/denise-sweeney.jpg"
					width={200}
					alt="Dr. Denise Sweeney"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Denise Sweeney is an ACOG board-certified OB-Gyn specializing in gynecologic care. While attending USC as an undergraduate, 
				she developed a love for teaching and outreach to the under-served surrounding community. This passion continued into medical school, where she 
				organized medical students to visit local schools and teach sexual health (sex-ed).</p>

				<p data-aos="fade-up">During residency, she started a gynecologic clinic for uninsured and migrant patients in Virginia that drew patients from 
				throughout the state. As a native Californian, her “Spanglish” was an invaluable asset to the new migrant workers in Virginia.</p>

				<p data-aos="fade-up">Upon completing residency, she moved back to California with her family, where she has spent the last 20 years 
				practicing in the Sacramento &amp; Roseville, California area. Dr. Sweeney’s practice has focused exclusively on gynecologic care since 2013.</p>

				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
	if((this.props.publicView) || (localStorage.getItem(this.props.localStorageID)) ) {
		this.setState({
		viewAllowed: true
	})}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
		publicView
    />
  )
}

export default FullBody
