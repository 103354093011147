import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import FormLanding from "./../screens/webinar-replay/2022-06-29-sweeney/form-landing"
import BodyLanding from "./../screens/webinar-replay/2022-06-29-sweeney/body-landing"

const SweeneyReplay = () => {
    return (
    <React.Fragment>
      <FormLanding />
      <BodyLanding pageName="display" localStorageID="2022-06-29-sweeney-webinar-replay" />
    </React.Fragment>
  )
}
export default SweeneyReplay

export const Head = () => (
  <Seo
		title="Webinar Replay: Revolutionizing STI Testing for You and Your Patients"
		description="Presenter Dr. Denise Sweeney talks about revolutionizing STI testing for you and your patients."
		image="/meta/webinar-sweeney-2022-06-29.jpg"
		recaptcha
    />
)
