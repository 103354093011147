import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Container from "components/container"
import WebinarForm from "components/webinar/form"
import WebinarPresenter from "components/webinar/presenter"
import WebinarContainer from "components/webinar/form-container"

const FORM_ID=process.env.GATSBY_FORM_WEBINAR_2022_06_29_SWEENEY

const FormFull = props => {

  return (
    <WebinarContainer
			viewAllowed={props.viewAllowed}
    >
		<WebinarPresenter>
			<Container data-aos="fade-up">
			<React.Fragment>
			<WebinarForm
				videoDisplayTitle="Revolutionizing STI Testing for You and Your Patients"
				localStorageID="2022-06-29-sweeney-webinar-replay"
				pageSlug="/webinar-replay/2022-06-29-sweeney-view/"
				wordpressFormID={FORM_ID}
			/>
			</React.Fragment>	
				<div className="right__column">
					<h3>Presenter</h3>
					<div className="columnPresenter">
					<StaticImage
						src="../../../../assets/images/webinar-replay/denise-sweeney.jpg"
						width={200}
						alt="Dr. Denise Sweeney"
						className="presenter__image"
					/>
					<p><span className="title">Denise L. Sweeney, MD, FACOG</span><br />
						Associates in Women’s Healthcare<br />
						Life Studios Medical Group, CEO						
					</p>
					</div>
				</div>
			</Container>
		</WebinarPresenter>
    </WebinarContainer>
  )
}

export default FormFull
